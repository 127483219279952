import type { KftDynamicContentMetrics } from '~/utils/kftApiUtils'

// Function to replace placeholders with actual values
export const replacePlaceholders = (
  str: string,
  metrics: KftDynamicContentMetrics
): string => {
  return str.replace(/{(\w+)}/g, (match, key) => {
    const metric = metrics[key as keyof KftDynamicContentMetrics]
    const shouldReplace = metric !== undefined && metric !== null
    if (shouldReplace) {
      return metrics[key as keyof KftDynamicContentMetrics]
    }
    return match
  })
}

// Recursive function to traverse and update all string fields, excluding metadata
export const updateObjectWithMetrics = <T>(
  obj: T,
  metrics: KftDynamicContentMetrics,
  metadataFields: string[]
) => {
  for (const key in obj) {
    if (metadataFields?.includes(key)) {
      // Skip metadata fields
      continue
    }

    if (typeof obj[key] === 'string') {
      // Replace placeholders in string fields
      obj[key] = replacePlaceholders(obj[key], metrics)
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      // Recurse into nested objects
      updateObjectWithMetrics(obj[key] as T, metrics, metadataFields)
    }
  }
}
export const dynamicContentTransformer = <T>(
  data: T,
  metrics: KftDynamicContentMetrics
): T => {
  // List of metadata fields to exclude from replacement
  const metadataFields = [
    'createdAt',
    'updatedAt',
    'publishedAt',
    'slug',
    'locale',
    'metaImage',
    'id',
    'image',
  ]
  const obj = data
  updateObjectWithMetrics<T>(obj, metrics, metadataFields)

  return obj
}
